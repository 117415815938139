import store from "@/store";

// 时间戳装换为正常时间格式
export function timeFormat(time) {
  let year = new Date(time).getFullYear();
  let month = new Date(time).getMonth() + 1;
  let day =
    new Date(time).getDate().toString().length == 1
      ? "0" + new Date(time).getDate()
      : new Date(time).getDate();
  let hours =
    new Date(time).getHours().toString().length == 1
      ? "0" + new Date(time).getHours()
      : new Date(time).getHours();
  let minutes =
    new Date(time).getMinutes().toString().length == 1
      ? "0" + new Date(time).getMinutes()
      : new Date(time).getMinutes();
  let temp = `${year}-${month}-${day} ${hours}:${minutes}`;
  return temp;
}

// 年-月，日期格式
export function getMonth() {
  let year = new Date(Date.now()).getFullYear();
  let month =
    (new Date(Date.now()).getMonth() + 1).toString().length == 1
      ? "0" + (new Date(Date.now()).getMonth() + 1)
      : new Date(Date.now()).getMonth() + 1;
  return `${year}-${month}`;
}

// 年-月-日，日期格式
export function getDate(time) {
  let year = new Date(time).getFullYear();
  let month = new Date(time).getMonth() + 1;
  let day =
    new Date(time).getDate().toString().length == 1
      ? "0" + new Date(time).getDate()
      : new Date(time).getDate();
  let temp = `${year}-${month}-${day}`;
  return temp;
}

// 状态装换
export function renderStatus(status) {
  switch (status) {
    case "CLOSE":
      return "已关闭";

    case "OVER":
      return "已完成";

    case "NOSTART":
      return "未开始";

    case "INSTART":
      return "未开始和进行中";

    case "AUDIT":
      return "待审核";

    case "PROGRESS":
      return "进行中";

    case "PROGRESS_NO_OVER":
      return "进行中（分派未完成）";

    case "PROGRESS_OVER":
      return "进行中（分派完成）";

    case "PEFUSE":
      return "已拒绝";

    case "PEFUND":
      return "退款中";

    case "NOCOLLECTION":
      return "待催收";

    case "ALREADYCOLLECTION":
      return "催收完成";

    case "RECALL":
      return "被撤回";

    case "REFUND":
      return "退款";

    case "SUCCESS":
      return "成功";

    case "UNPAID":
      return "未支付";

    case "IN":
      return "进行中";

    case "ABNORMAL":
      return "异常";

    case "CANCEL":
      return "取消";
  }
}

// 类型转换
export function renderType(type) {
  switch (type) {
    case "TAOBAO":
      return "淘宝";

    case "JD":
      return "京东";

    case "ALIBABA":
      return "阿里巴巴";

    case "TMALL":
      return "天猫";

    case "PDD":
      return "拼多多";

    case "VIP":
      return "唯品会";

    case "SHOPEE":
      return "虾皮";

    case "AMAZON":
      return "亚马逊";

    case "DOUYIN":
      return "抖音";

    case "KUAISHOU":
      return "快手";

    case "SUNING":
      return "苏宁";

    default:
      return type;
  }
}

// 整店状态排序
export function sortStoreStatus(status) {
  let arr = ["未开始", "被撤回", "待提交", "待审核", "进行中", "已完成"];
  let arrIndex = arr.length - 1;
  let role = store.state.user.role;

  status.forEach(status => {
    const statusIndex = arr.indexOf(status);
    if (statusIndex < arrIndex) {
      arrIndex = statusIndex;
    }
  });

  if (
    role == "SEND-COMPANY" ||
    role == "SEND-SUPERVISOR" ||
    role == "SEND-GROUP" ||
    role == "SEND-GROUP-VICE" ||
    role == "SEND-FINANCE"
  ) {
    if (arr[arrIndex] == "进行中") {
      return false;
    }
  }

  return arr[arrIndex];
}

// 佣金身份转换
export function renderCommissionType(type) {
  switch (type) {
    case "PROXY":
      return "代理 (一级商家)";
    case "GROUP":
      return "组长 (加盟商)";
    case "CUSTOMER":
      return "客服";
  }
}
